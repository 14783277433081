import {React, useState, useEffect} from "react";
import HomeFooterFr from "./HomeFooterFr";
import engineer from "../../assets/images/mystic6.avif";
import {Helmet} from "react-helmet";
import TopAppBarFr from "./TopAppBarFr";



function LazyBackgroundImageKnowledge({img}) {
  const [loaded, setLoaded] = useState(false);
  const handleLoad = () => {
    setLoaded(true);
  };
return (
 <div className="h-full"
    style={{
      backgroundImage: `url("${img}")`,
      backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center",
      filter: loaded ? "none" : "blur(20px)",
      transition: "filter 0.5s",
    }}>
    <img src={img} alt="" onLoad={handleLoad} style={{ display: "none" }} />
    {loaded}

    <div className="flex flex-col items-center pt-24">
            <h1 className="font-bold text-white text-3xl" >
                Dreams Gist
            </h1>
      <p className="text-white">Interprétations détaillées de rêves.</p>
      <p className="text-white">Horoscopes journaliers et hebdomadaires</p>
    </div>


    <div className="md:container md:mx-auto justify-center flex justify-center items-center px-auto" >
      <div className="flex flex-col justify-center items-start row-start-2 sm:row-start-1 py-12 px-6">
        <br /><br /><br />
        <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium text-white leading-normal">
        <br />  <strong></strong>
        </h1><br /><br />
        <p className="text-xl text-white mt-4 mb-6">
          <br /> <br /><br />
        </p>  
        </div>
          <div className="flex w-full">
            <div className="h-full w-full" >
            </div>
          </div>
    </div>
    <div className="flex flex-col items-center py-6 ">
            <p className="text-lg text-base text-white text-xl font-semibold italic">
                <span className="text-orange-400">
                  <a href="/reves/a" className="btn btn-primary btn m-1">Rêves </a> </span> & 
                 <span className="text-sky-600"> <a href="/horoscope/fr" className="btn btn-secondary btn m-1">Horoscope</a> </span>
            </p>

    </div>
    <div className="py-6">

    </div>
 </div>
);
}



function AccueilDescription(){
  return (
    <>
<div className="text-base-content  pt-12  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="py-2 lg:py-4">
    <div className="py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
      <h2 className="flex justify-center items-center sm:text-2xl text-xl m-4 pb-6 text-purple-500 ">
        <b>Pourquoi quelqu'un devrait-il s'interesser à la signification des rêves ?</b>
      </h2>

      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Comprendre le langage des rêves</h3>
      <p>Les rêves font indéniablement partie de notre esprit endormi. 
        Dans certains rêves, nous avons la vie que nous pensons mériter, où nous sommes heureux et entourés d'un bel environnement 
        et de bonnes personnes. Dans d'autres rêves, nous sommes piégés devant nos peurs les plus profondes et nos émotions 
        les plus tristes. Les rêves captivent l'imagination humaine depuis l'aube de la civilisation. 
        Ils tissent ensemble des émotions, des souvenirs et des symboles dans diverses histoires. 
        Il faut comprendre le langage des rêves pour savoir de quelle histoire parle un rêve. 
        Oui, les rêves sont un langage qu'il faut apprendre. </p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Que sont les rêves ?</h3>
      <p>
      D'un point de vue scientifique, les rêves sont une série d'images, d'émotions et de sensations qui se produisent 
      pendant des phases spécifiques du sommeil, en particulier pendant le sommeil paradoxal. 
      Cependant, ils représentent bien plus qu'une simple activité neuronale : 
      ils sont des fenêtres sur nos pensées les plus profondes, nos peurs, nos désirs et nos conflits non résolus.
      </p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Les pionniers de la recherche sur les rêves</h3>
      <p>
      L'étude des rêves a attiré des esprits brillants tout au long de l'histoire. 
      Sigmund Freud a révolutionné notre compréhension avec son ouvrage fondateur « L'interprétation des rêves » (1900), 
      affirmant que les rêves étaient la « voie royale vers l'inconscient ». 
      Carl Jung a développé ce fondement en introduisant des concepts tels que l'inconscient collectif et les archétypes 
      universels qui apparaissent dans les rêves à travers les cultures. 
      Des chercheurs modernes comme J. Allan Hobson ont remis en question les vues psychanalytiques traditionnelles avec 
      son hypothèse d'activation-synthèse, tandis que l'analyse du contenu de milliers de rêves par Calvin Hall a 
      révélé des modèles communs dans les thèmes des rêves à travers différentes populations.
      </p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Histoires de rêves remarquables</h3>
      <p>
      L'histoire regorge d'exemples de rêves inspirant de grandes réalisations. 
      Dmitri Mendeleïev a affirmé que le tableau périodique lui était venu dans un rêve. 
      Paul McCartney a composé « Yesterday » après avoir entendu la mélodie dans son sommeil. 
      « Frankenstein » de Mary Shelley a été inspiré par un cauchemar saisissant. 
      Ces exemples suggèrent que les rêves peuvent être de puissants catalyseurs de créativité et de résolution de problèmes.
      </p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Pourquoi l'interprétation des rêves est importante ?</h3>
      <p>
        &bull; <b>Découverte de soi:</b> les rêves révèlent souvent des aspects de nous-mêmes que nous ne reconnaissons pas consciemment<br />
        &bull; <b>Résolution de problèmes: </b> les rêves peuvent offrir de nouvelles perspectives sur les défis auxquels nous sommes confrontés<br />
        &bull; <b>Gestion des émotions: </b> ils nous aident à gérer les émotions et les expériences difficiles<br />
        &bull; <b>Reconnaissance de modèles:</b> les rêves récurrents peuvent mettre en évidence des thèmes importants dans nos vies<br />
        &bull; <b>Croissance personnelle:</b> le travail sur les rêves peut faciliter le développement psychologique et spirituel<br />
      </p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Apprendre le langage</h3>
      <p>Chaque rêve est unique au rêveur, coloré par des expériences personnelles et un contexte culturel. 
        Bien que certains symboles puissent avoir des significations universelles, 
        la véritable signification d'un rêve réside souvent dans les associations personnelles du rêveur et dans 
        sa situation de vie actuelle. 
        En apprenant à interpréter vos rêves, vous ne vous contentez pas de décoder des histoires nocturnes : 
        vous vous lancez dans un voyage de découverte de soi, en accédant à une sagesse intérieure qui vous parle depuis toujours. 
        Que vous cherchiez des réponses, que vous releviez des défis ou que vous soyez simplement curieux des mystères de votre esprit, 
        l'interprétation des rêves offre un chemin fascinant vers une compréhension plus profonde de vous-même. 
        <b> Commencez votre voyage dans le monde des rêves.
 Votre inconscient a des histoires à raconter : êtes-vous prêt à les écouter ?</b>
</p>
    </div>

    <div className="py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
      <h2 className="flex justify-center items-center sm:text-2xl text-xl pb-6 m-4 text-purple-500 ">
        <b>Quel est l'impact de l'astrologie sur notre vie quotidienne ?</b>
      </h2>

      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Comprendre l'astrologie
        </h3>
      <p>
      Depuis l'Antiquité, les humains se tournent vers les étoiles pour trouver des conseils, 
      des modèles et une signification dans le mouvement des étoiles, des planètes et d'autres corps célestes. 
      L'astrologie – l'étude de la relation entre les mouvements planétaires et les événements 
      et les personnalités sur Terre – est passée d'une sagesse ancienne à un outil moderne d'auto-réflexion et de perspicacité personnelle.
      </p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Qu'est-ce que l'astrologie ?
        </h3>
      <p>
      L'astrologie est basée sur le principe selon lequel les positions du Soleil, de la Lune, des planètes et des 
      constellations au moment de notre naissance créent un plan cosmique unique qui influence notre personnalité et notre chemin de vie. 
      Cette pratique ancienne combine la précision mathématique avec l'interprétation symbolique pour créer un cadre permettant de nous comprendre nous-mêmes et notre place dans l'univers.
      </p>
      
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Pourquoi suivre votre horoscope ?
        </h3>
      <p>
      Une lecture régulière de l'horoscope offre de nombreux avantages: <br />
• Fournit un cadre pour la réflexion quotidienne<br />
• Offre différentes perspectives sur les défis personnels<br />
• Crée une prise de conscience des cycles naturels et du calendrier<br />
• Encourage la découverte de soi et la croissance personnelle<br />
• Vous connecte à la sagesse ancienne dans un contexte moderne<br />
      </p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Commencez votre voyage cosmique
        </h3>
      <p>Que vous recherchiez des conseils quotidiens, une meilleure compréhension de vous-même ou une connexion avec 
        les rythmes cosmiques, l'astrologie offre une riche tradition de sagesse. 
        Vos horoscopes quotidiens, hebdomadaires et mensuels sont plus que des prédictions : 
        ce sont des outils pour naviguer dans le voyage de la vie avec plus de conscience et de détermination. 
        Découvrez ce que les étoiles vous réservent.
      <b> Votre histoire cosmique est écrite dans les cieux: laissez-nous vous aider à la lire.</b>  </p>



    </div>



  </div>
</div>
;

    </>
  )
}










function Home () {
  return(
    <>
    <TopAppBarFr></TopAppBarFr>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Page d'accueil Dreams Gist</title>
        <meta name="description" content="Accédez aux interprétations détaillées de rêves sur notre site Dreams Gist." />              
        <meta name="keywords" content="rêves,interprétations détaillées de rêves"/>
        <meta property="og:title" content="Page d'accueil Dreams Gist" />        
        <meta property="og:description" content="Accédez aux interprétations détaillées de rêves sur notre site Dreams Gist." />        
        <meta property="og:site_name" content="Page d'accueil Dreams Gist" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
    </Helmet>


            <LazyBackgroundImageKnowledge img={engineer}></LazyBackgroundImageKnowledge>
            <AccueilDescription   />
        <HomeFooterFr></HomeFooterFr>
    </>
  )
}
export default Home;