import {React, useState} from "react";
import notfound from "../assets/images/404.png";

function NotFound(){
    return(
        <>
        <br /><br /><br />
        <div className="py-2 flex justify-center items-center  bg-base-100">
                <div className="py-6 sm:py-12">
            <div className="sm:mx-auto sm:max-w-lg sm:rounded-lg">
                <div className="mx-auto max-w-md px-4">
                
                <img className="mx-auto w-48" src={notfound} alt="logo" /> <br />
                <div className="divide-y divide-gray-300/50">
                    <div className="space-y-6 py-8 text-base leading-7 text-black-500 dark:text-white">
                    <h2 className="text-lg"><b>Page not found</b></h2>
                    <p>
                    The page you are looking for doesn't exist. 

                    </p><br />
                    <a href="/"><button className="btn btn-solid-primary">Back Home</button></a>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        
        </>
    );
}

export default NotFound;