import React from "react";
import ukraine from "../../assets/images/ukraineflag.png";
import logo from "../../assets/images/Dreams1080r.png";

function TopAppBarFr(){

    function onLoad(){
      //const button = document.querySelector('#menu-button'); // Hamburger Icon
      const menu = document.querySelector('#menu'); // Menu
    
      //button.addEventListener('click', () => {
        menu.classList.toggle('hidden');
      //});
    }
        return (
          <nav className="flex flex-wrap items-center justify-between w-full py-4 md:py-0 px-4 
          text-lg text-gray-700  navbar-sticky navbar-glass">
           <div className="btn btn-circle btn-solid-secondary">
              <a href="/fr"><img src={logo} width={32} height={32} alt="logo"></img></a>
            </div>
           
            <svg width="28px" height="28px" onClick={onLoad} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" 
            className="cursor-pointer md:hidden block dark:text-white">
                            <g id="Menu / Menu_Alt_05">
                                <path id="Vector" d="M12 17H19M5 12H19M5 7H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
           
           <div className="hidden w-full md:flex md:items-center md:w-auto" id="menu">
              <ul
                className="
                  pt-4
                  text-base text-gray-700
                  md:flex
                  md:justify-between 
                  md:pt-0">
                <li>
                <div className="dropdown-container md:p-2 py-2">
                  <div className="dropdown">
                    <label className="btn-rounded btn btn-solid-secondary" tabIndex="0">Français
                      <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512" height={20} width={20}>
                      <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" 
                      d="M112 184l144 144 144-144"/></svg>
                    </label>
                    <div className="dropdown-menu dropdown-menu-bottom-right">
                      <a className="dropdown-item text-sm text-black-500 dark:text-white"  href="/dreams/a">English</a>
                    </div>
                  </div>
                </div>
                  
                </li>
                <li>
              <a className="text-black-500 dark:text-white md:p-4 py-2 block hover:text-purple-700 " href="/reves/a">Rêves</a>
            </li>
                <li>
                  <a className="text-black-500 dark:text-white md:p-4 py-2 block hover:text-purple-700 " href="/horoscope/fr">Horoscope</a>
                </li>
                <li>
                  <a className="text-black-500 dark:text-white md:p-4 py-2 block hover:text-purple-700 " href="/a-propos">A propos</a>
                </li>
              </ul>
            </div>
        </nav>
           
    
      
        );
      }


      export default TopAppBarFr;