import {React, useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom';
import TopAppBarFr from "../fr/TopAppBarFr";
import {DreamViewText, DreamViewImage} from "../DreamView"; 
  

function FrDreams() {
  let { category } = useParams();
  const [searchItem, setSearchItem] = useState('')
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const url = `https://goldfish-app-asx3v.ondigitalocean.app/dream/fr/start/${category}`;
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [category])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {

  return (
    <>
    <TopAppBarFr></TopAppBarFr>


      <Helmet>
          <meta charSet="utf-8" />
          <title>Interprétation de rêves</title>
          <meta name="description" content="Consulter l'interprétation détaillée de milliers de rêves." />              
          <meta name="keywords" content="rêves,interprétation détaillée de milliers de rêves"/>
          <meta property="og:title" content="Interprétation de rêves" />        
          <meta property="og:description" content="Consulter l'interprétation détaillée de milliers de rêves." />        
          <meta property="og:site_name" content="Interprétation de rêves" />
          <meta property="og:locale" content="fr_FR" />
          <meta property="og:type" content="article" />
      </Helmet>

   
    { (category === 'a') &&
      <div className="text-base-content  pt-12  flex justify-center items-center  bg-base-100 mainDiv" >
          <div className="py-4 lg:py-6">

          <div className="py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
          <h1 className=" flex justify-center items-center sm:text-2xl text-xl m-4 text-purple-500 "><b>Rêves</b></h1><br /><br />
          <p className="text-black-500 dark:text-white font-bold">Les rêves agissent comme des fenêtres sur notre subconscient.</p>
          <p>Ils révèlent la matière première de nos pensées, de nos peurs et de nos désirs les plus profonds, souvent tissés dans un récit d'images et d'événements symboliques. 
            Comprendre le symbolisme et les messages cachés dans les rêves peut vous aider à les utiliser comme un outil de croissance personnelle. 
            L'interprétation des rêves vous permet de naviguer dans votre paysage intérieur et de découvrir des aspects cachés de votre psyché. 
            Nos rêves donnent un aperçu des recoins cachés de notre esprit, reflétant nos émotions refoulées, nos conflits non résolus et nos désirs secrets. 
            Ce sont des expressions non filtrées de notre monde intérieur, adaptées par notre subconscient en histoires qui capturent notre état psychologique. 
            Comprendre le symbolisme des rêves et les messages cachés Les rêves parlent un langage de symboles où chaque objet, personne et scénario représente un aspect de notre vie éveillée. 
            Déchiffrer ce langage symbolique, c'est comme déchiffrer un puzzle complexe, offrant un aperçu approfondi de nos émotions, de nos relations et de nos expériences de vie.</p><br /><br />
            <p className="text-black-500 dark:text-white font-bold">Avantages de l'étude de la signification des rêves pour le développement personnel</p>
            <p>L'étude des rêves peut contribuer de manière significative à notre développement personnel. Interpréter les rêves peut nous aider à mieux comprendre nos peurs, nos inquiétudes, nos aspirations et notre potentiel. 
            Cette prise de conscience de soi nous donne la force d'affronter nos conflits intérieurs et d'accepter notre véritable identité. </p>
            <p className="text-black-500 dark:text-white font-bold">Vous trouverez ci-dessous une liste de rêves et leurs interprétations détaillées.</p>
            <p className="text-purple-700 ">Tous les rêves commençant par ce caractère s'affichent lorsque vous cliquez sur une lettre, comme A, L, I, J, etc.</p>
            <p>
                  <span>L'application native peut être téléchargée sur différentes platformes: </span>
                            <a className="btn btn-ghost btn-circle btn-sm mr-4" href="https://play.google.com/store/apps/details?id=tntechnology.app.dreams" target={"_blank"} rel="noopener noreferrer">
                                <svg width="20px" height="20px" viewBox="19.932999999999996 68.509 228.155 228.155" xmlns="http://www.w3.org/2000/svg"><path d="M101.885 207.092c7.865 0 14.241 6.376 14.241 14.241v61.09c0 7.865-6.376 14.24-14.241 14.24-7.864 0-14.24-6.375-14.24-14.24v-61.09c0-7.864 6.376-14.24 14.24-14.24z" fill="#a4c639"/><path d="M69.374 133.645c-.047.54-.088 1.086-.088 1.638v92.557c0 9.954 7.879 17.973 17.66 17.973h94.124c9.782 0 17.661-8.02 17.661-17.973v-92.557c0-.552-.02-1.1-.066-1.638H69.374z" fill="#a4c639"/><path d="M166.133 207.092c7.865 0 14.241 6.376 14.241 14.241v61.09c0 7.865-6.376 14.24-14.241 14.24-7.864 0-14.24-6.375-14.24-14.24v-61.09c0-7.864 6.376-14.24 14.24-14.24zM46.405 141.882c7.864 0 14.24 6.376 14.24 14.241v61.09c0 7.865-6.376 14.241-14.24 14.241-7.865 0-14.241-6.376-14.241-14.24v-61.09c-.001-7.865 6.375-14.242 14.241-14.242zM221.614 141.882c7.864 0 14.24 6.376 14.24 14.241v61.09c0 7.865-6.376 14.241-14.24 14.241-7.865 0-14.241-6.376-14.241-14.24v-61.09c0-7.865 6.376-14.242 14.241-14.242zM69.79 127.565c.396-28.43 25.21-51.74 57.062-54.812h14.312c31.854 3.073 56.666 26.384 57.062 54.812H69.79z" fill="#a4c639"/><path d="M74.743 70.009l15.022 26.02M193.276 70.009l-15.023 26.02" fill="none" stroke="#a4c639" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/><path d="M114.878 102.087c.012 3.974-3.277 7.205-7.347 7.216-4.068.01-7.376-3.202-7.388-7.176v-.04c-.011-3.975 3.278-7.205 7.347-7.216 4.068-.011 7.376 3.2 7.388 7.176v.04zM169.874 102.087c.012 3.974-3.277 7.205-7.347 7.216-4.068.01-7.376-3.202-7.388-7.176v-.04c-.011-3.975 3.278-7.205 7.347-7.216 4.068-.011 7.376 3.2 7.388 7.176v.04z" fill="#ffffff"/></svg>
                                </a>
                                <a className="btn btn-ghost btn-circle btn-sm" href="https://www.microsoft.com/store/apps/9NX4RB7S5HKW" target={"_blank"} rel="noopener noreferrer">
                                <svg width="20px" height="20px" viewBox="-0.5 0 257 257" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><path d="M0 36.357L104.62 22.11l.045 100.914-104.57.595L0 36.358zm104.57 98.293l.08 101.002L.081 221.275l-.006-87.302 104.494.677zm12.682-114.405L255.968 0v121.74l-138.716 1.1V20.246zM256 135.6l-.033 121.191-138.716-19.578-.194-101.84L256 135.6z" fill="#00ADEF"/></svg>
                                </a> . <span  className="text-black-500 dark:text-white"></span> 
                  </p>
                </div>
        </div>
      </div>
    }
    {(category !== 'a') && <div className="py-12"></div>}
      
      <div className="text-base-content bg-base-100 flex justify-center items-center">
          <ul className="btn-group px-4">
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/a">A</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/b">B</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/c">C</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/d">D</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/e">E</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/f">F</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/g">G</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/h">H</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/i">I</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/j">J</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/k">K</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/l">L</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/m">M</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/n">N</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/o">O</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/p">P</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/q">Q</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/r">R</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/s">S</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/t">T</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/u">U</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/v">V</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/w">W</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/x">X</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/y">Y</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/reves/z">Z</a></div></li>
          </ul>
        </div>


      <div className="text-base-content bg-base-100 px-2 py-2 flex justify-center items-center">
        <input  type="text" placeholder="Rechercher içi" className="mt-4 input input-rounded input-secondary w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
      </div>

      <div className="text-base-content bg-base-100 px-4 py-6">
        
        {
        items.filter((dream) => //items here
        {
          if(searchItem === "") {
            return dream;
          }
          else if 
          (dream.Word.toLowerCase().includes(searchItem.toLowerCase())) {
            return dream;
          }
          else return null
          
        }).map(
            dream => 
                dream.ImageUrl === "" ?
            <DreamViewText key={dream.Id} id={dream.Id} 
              word={dream.Word}
              interpretation={dream.Interpretation}
              ></DreamViewText>
            :
            <DreamViewImage key={dream.Id} id={dream.Id} 
              word={dream.Word}
              interpretation={dream.Interpretation}
              image={dream.ImageUrl}
              ></DreamViewImage>
            
            )
        }
      </div>
    </>
  );}
  };

export default  FrDreams;