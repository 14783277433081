import React from "react";
import {Helmet} from "react-helmet";
import TopAppBarFr from "../fr/TopAppBarFr";
function AboutFr() {
    return (
      <>
      <TopAppBarFr></TopAppBarFr>

      <Helmet>
          <meta charSet="utf-8" />
          <title>A propos</title>
          <meta name="description" content="La raison pour laquelle nous avons bâti ce site web d'interprétation de rêves." />              
          <meta name="keywords" content="à propos"/>
          <meta property="og:title" content="A propos" />        
          <meta property="og:description" content="La raison pour laquelle nous avons bâti ce site web d'interprétation de rêves." />        
          <meta property="og:site_name" content="A propos" />
          <meta property="og:locale" content="fr_FR" />
          <meta property="og:type" content="article" />
        </Helmet>

          <br /><br />
          <div className="py-6 sm:py-12">
            <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
              <div className="mx-auto max-w-md px-4">
                <h1><b> A propos</b></h1>
                <div className="divide-y divide-gray-300/50">
                  <div className="space-y-6 py-8 text-base leading-7 text-gray-600 dark:text-white">
                    <p> <span className="font-bold text-purple-500">
                      Dreams Gist </span>rassemble une vaste collection de rêves et leur interprétation détaillée. 
                      Les rêves reflètent nos pensées, nos peurs et nos désirs les plus profonds, sous forme d'images symboliques. 
                      En créant ce site Web, <b>nous </b> voulons fournir un outil permettant aux gens de mieux se connaître. 
                      Un outil qui vous avertira constamment d'être plus prudent dans votre vie quotidienne. 
                      Un outil qui vous donnera de l'optimisme lorsque vous ferez face à des difficultés. 
                      Un outil qui vous indique continuellement une autre voie, et qu'une vie meilleure vous attend. 
                      Mais, qui sommes <b>nous </b> ?</p>
                    <p> 
                    « Nous », fait reférence à <a className="text-blue-400 hover:text-blue-600 font-bold" href="https://arfusoft.com/" target="_blank" rel="noreferrer">Arfusoft</a> , la société de développement de logiciels qui a construit ce site Web..
                    </p>
                  </div>
                </div>
              </div>
            </div>
      </div>

  
      </>
  
    );
  }
  export default AboutFr;