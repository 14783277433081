import {React, useState, useEffect} from "react";
import { useParams } from 'react-router-dom';


function HoroscopeViewDayFr ({ date, horoscope_day}){
    return (
        <>
    <section className="text-gray-600 body-font">
    <div className="container mx-auto flex px-5 py-2 md:flex-row flex-col items-center">
      <div className="lg:flex-grow md:w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <p className="mb-8 leading-relaxed  text-black-500 dark:text-white "><span className="text-blue-700">Dâte: </span> {date}</p>
        <h2 className=" text-purple-700 flex justify-center items-center text-lg"><b>Horoscope du jour</b>   </h2>
        <p className="mb-8 leading-relaxed text-black-500 dark:text-white ">{horoscope_day}</p>
        <div className="flex justify-center">
        </div>
      </div>
    </div>
  </section><hr className="mx-12 md:mx-24" />
        </>
    )
}

function HoroscopeViewWeekFr ({ horoscope_week}){
  return (
      <>
  <section className="text-gray-600 body-font">
  <div className="container mx-auto flex px-5 py-2 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <h2 className=" text-purple-700 flex justify-center items-center text-lg"><b>Horoscope de la semaine</b>   </h2>
      <p className="mb-8 leading-relaxed text-black-500 dark:text-white ">{horoscope_week}</p>
      <div className="flex justify-center">
      </div>
    </div>
  </div>
</section><hr className="mx-12 md:mx-24" />
      </>
  )
}



function HoroscopeViewDayEn ({ date,  horoscope_day}){
  return (
      <>
  <section className="text-gray-600 body-font">
  <div className="container mx-auto flex px-5 py-2 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <p className="mb-8 leading-relaxed  text-black-500 dark:text-white "><span className="text-blue-700">Date: </span> {date}</p>
      <h2 className=" text-purple-700 flex justify-center items-center text-lg"><b>Horoscope of the day</b>   </h2>
      <p className="mb-8 leading-relaxed text-black-500 dark:text-white ">{horoscope_day}</p>
      <div className="flex justify-center">
      </div>
    </div>
  </div>
</section><hr className="mx-12 md:mx-24" />
      </>
  )
}

function HoroscopeViewTomorrowEn ({ horoscope_tomorrow}){
return (
    <>
<section className="text-gray-600 body-font">
<div className="container mx-auto flex px-5 py-2 md:flex-row flex-col items-center">
  <div className="lg:flex-grow md:w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
    <h2 className=" text-purple-700 flex justify-center items-center text-lg"><b>Horoscope of tomorrow</b>   </h2>
    <p className="mb-8 leading-relaxed text-black-500 dark:text-white ">{horoscope_tomorrow}</p>
    <div className="flex justify-center">
    </div>
  </div>
</div>
</section><hr className="mx-12 md:mx-24" />
    </>
)
}

function HoroscopeViewYesterdayEn ({ horoscope_yesterday}){
return (
    <>
<section className="text-gray-600 body-font">
<div className="container mx-auto flex px-5 py-2 md:flex-row flex-col items-center">
  <div className="lg:flex-grow md:w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
    <h2 className=" text-purple-700 flex justify-center items-center text-lg"><b>Horoscope of yesterday</b>   </h2>
    <p className="mb-8 leading-relaxed text-black-500 dark:text-white ">{horoscope_yesterday}</p>
    <div className="flex justify-center">
    </div>
  </div>
</div>
</section><hr className="mx-12 md:mx-24" />
    </>
)
}
function HoroscopeViewWeekEn ({ horoscope_week}){
  return (
      <>
  <section className="text-gray-600 body-font">
  <div className="container mx-auto flex px-5 py-2 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <h2 className=" text-purple-700 flex justify-center items-center text-lg"><b>Horoscope of the week</b>   </h2>
      <p className="mb-8 leading-relaxed text-black-500 dark:text-white ">{horoscope_week}</p>
      <div className="flex justify-center">
      </div>
    </div>
  </div>
  </section><hr className="mx-12 md:mx-24" />
      </>
  )
  }
  function HoroscopeViewMonthEn ({ horoscope_month}){
    return (
        <>
    <section className="text-gray-600 body-font">
    <div className="container mx-auto flex px-5 py-2 md:flex-row flex-col items-center">
      <div className="lg:flex-grow md:w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
        <h2 className=" text-purple-700 flex justify-center items-center text-lg"><b>Horoscope of the month</b>   </h2>
        <p className="mb-8 leading-relaxed text-black-500 dark:text-white ">{horoscope_month}</p>
        <div className="flex justify-center">
        </div>
      </div>
    </div>
    </section><hr className="mx-12 md:mx-24" />
        </>
    )
    }



export {HoroscopeViewDayFr,HoroscopeViewWeekFr, HoroscopeViewDayEn,HoroscopeViewTomorrowEn,HoroscopeViewYesterdayEn,
  HoroscopeViewWeekEn, HoroscopeViewMonthEn
 };