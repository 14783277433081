import { Outlet } from "react-router-dom";
//import TopAppBar from "./TopAppBar";

const Layout = () => {
    return(
        <div>
{/*<Navbar></Navbar>
<TopAppBar></TopAppBar>*/}
            <Outlet />
           
        </div>
    )
}

export default Layout