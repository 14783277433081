import React from "react";
import {Helmet} from "react-helmet";
import TopAppBarEn from "./TopAppBar";
import aries from "../../assets/images/aries.jpg";
import taurus from "../../assets/images/taurus.jpg";
import gemini from "../../assets/images/gemini.jpg";
import cancer from "../../assets/images/cancer.jpg";
import leo from "../../assets/images/leo.jpg";
import virgo from "../../assets/images/virgo.jpg";
import libra from "../../assets/images/libra.jpg";
import scorpio from "../../assets/images/scorpio.jpg";
import sagittarius from "../../assets/images/sagittarius.jpg";
import capricorn from "../../assets/images/capricorn.jpg";
import aquarius from "../../assets/images/aquarius.jpg";
import pisces from "../../assets/images/pisces.jpg";



function HoroscopeEnMain() {
    return (
        <>
            <TopAppBarEn></TopAppBarEn>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Horoscope</title>
                <meta name="description" content="Select a zodiac sign to get detailed horoscope of the day, tommorow, yesterday, week and month of that sign." />              
                <meta name="keywords" content="horoscope of the day,Aries,Gemini,Cancer,Leo,Virgo,Libra,Scorpio,Sagittarius,Capricorn,Aquarius,Pisces"/>
                <meta property="og:title" content="Horoscope" />        
                <meta property="og:description" content="Select a zodiac sign to get detailed horoscope of the day, tommorow, yesterday, week and month of that sign." />        
                <meta property="og:site_name" content="Horoscope" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
            </Helmet>

           
        {/**Description */}
        <section className="text-gray-600 body-font relative">
            <div className="container px-5 py-12 mx-auto">
                <div className="flex flex-col text-justify w-full mb-12">
                    <h1 className=" flex justify-center items-center sm:text-2xl text-xl m-4 text-purple-500 "> <b>Horoscope</b></h1>
                    <p className="text-black-500 dark:text-white">
                    Astrology has been part of human culture for thousands of years and has influenced countless decisions and beliefs. Of its many aspects, the daily horoscope is the best-known and most widely used aspect of astrology.
                    <b> Why do people read their horoscope daily and what it means for them?</b>    </p>
                    <p className="text-black-500 dark:text-white">Horoscopes are usually read for entertainment.
                    People turn to astrology when they want to keep busy or relax.
                    Here are some reasons you should read it daily: </p><br /><br />
                    <p className="text-black-500 dark:text-white font-bold">To be more optimistic. </p>
                    <p className="text-black-500 dark:text-white">You can't force everyone to believe in predictions.
                    However, looking at the signs and learning good news can change things, especially in bad times. Reading the horoscope can help people remain optimistic despite what they are going through. In difficult moments, a little hope can lift people's spirits and prevent them from giving up.
                    If you are waiting for a sign to organize your thoughts and make the best choices, horoscopes can help you. They will boost your self-confidence and guide you to make wise decisions in relationships, careers, studies, and other aspects of life. Therefore, anticipation is the key to keeping an open mind and preparing for what is to come.
                    Being open-minded and prepared will make things easier for you than not knowing what will happen.</p><br /><br />
                    <p className="text-black-500 dark:text-white font-bold">For Self Improvement </p>
                    <p className="text-black-500 dark:text-white">When you read your horoscope, you learn what astrology has to say about the negative things in your life.
                    These predictions will help you to recognize your mistakes and prevent them from leading to undesirable events.
                    They will also help you discover the good qualities that you already have within yourself and use them as strengths.
                    The more you learn about your goodness and use it to improve yourself, the more meaningful your life will be.</p><br /><br />
                    <p className="text-black-500 dark:text-white font-bold">Avoid Misunderstandings in Relationships </p>
                    <p className="text-black-500 dark:text-white">If you take the time to read what astrology has to say about your relationship with your partner, you can avoid serious problems.
                    On the other hand, if you are not yet committed to anyone, your horoscope will give you positive thoughts about your love life.
                                        </p>
                    <p className="text-purple-500 font-bold">Select a zodiac sign to access it's detailed horoscopes of the day, week and month. </p>

                </div>
            </div>
        </section>
        {/**Topics */}
        <section className="text-gray-600 body-font dark:text-white">
            <div className="container px-5 py-4 mx-auto">
                <div className="flex flex-wrap -m-4">
                    <div className="p-4 lg:w-1/3">
                        <div className="card shadow w-80 m-4">
                            <figure>
                                <img src={aries} alt="integral" />
                            </figure>
                            <div className="card-body flex items-center" >
                                <img className="avatar" src={aries} alt="integral" />
                                <h2 className="card-title"> Aries</h2>
                                <a  className="w-full btn bg-blue-700 hover:bg-blue-900 text-white btn-md" href="/horoscope/en/aries"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 lg:w-1/3">
                        <div className="card shadow w-80 m-4">
                            <figure>
                                <img src={taurus} alt="derivative" />
                            </figure>
                            <div className="card-body flex items-center">
                                <img className="avatar"  src={taurus} alt="derivative" />
                                <h2 className="card-title">Taurus</h2>
                                <a className="w-full btn bg-red-500 hover:bg-red-900 text-white btn-md" href="/horoscope/en/taurus"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                    </div>
    
                    <div className="p-4 lg:w-1/3">
                        <div className="card shadow w-80 m-4">
                            <figure>
                                <img src={gemini} alt="integral" />
                            </figure>
                            <div className="card-body flex items-center">
                                <img className="avatar"  src={gemini} alt="integral" />
                                <h2 className="card-title"> Gemini</h2>
                                <a className="w-full btn bg-purple-700 hover:bg-purple-900 text-white btn-md" href="/horoscope/en/gemini"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    
        <section className="text-gray-600 body-font dark:text-white">
            <div className="container px-5 py-4 mx-auto">
                <div className="flex flex-wrap -m-4">
                    <div className="p-4 lg:w-1/3">
                        <div className="card shadow w-80 m-4">
                            <figure>
                                <img src={cancer}  alt="slope" />
                            </figure>
                            <div className="card-body flex items-center">
                                <img className="avatar"  src={cancer}  alt="slope" />
                                <h2 className="card-title">Cancer</h2>
                                <a className="w-full btn bg-blue-700 hover:bg-blue-900 text-white btn-md" href="/horoscope/en/cancer"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 lg:w-1/3">
                        <div className="card shadow w-80 m-4">
                            <figure>
                                <img src={leo} alt="ineq" />
                            </figure>
                            <div className="card-body flex items-center">
                                <img className="avatar"  src={leo} alt="ineq" />
                                <h2 className="card-title">Leo</h2>
                                <a className="w-full btn bg-red-500  hover:bg-red-800 text-white btn-md" href="/horoscope/en/leo"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 lg:w-1/3">
                        <div className="card shadow w-80 m-4">
                            <figure>
                                <img src={virgo} alt="ineq" />
                            </figure>
                            <div className="card-body flex items-center">
                                <img className="avatar"  src={virgo} alt="ineq" />
                                <h2 className="card-title">Virgo</h2>
                                <a className="w-full btn bg-purple-600  hover:bg-purple-800 text-white btn-md" href="/horoscope/en/virgo"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
        <section className="text-gray-600 body-font dark:text-white">
            <div className="container px-5 py-4 mx-auto">
                <div className="flex flex-wrap -m-4">
                    <div className="p-4 lg:w-1/3">
                        <div className="card shadow w-80 m-4">
                            <figure>
                                <img src={libra}  alt="slope" />
                            </figure>
                            <div className="card-body flex items-center">
                                <img className="avatar"  src={libra}  alt="slope" />
                                <h2 className="card-title">Libra</h2>
                                <a className="w-full btn bg-red-500 hover:bg-red-900 text-white btn-md" href="/horoscope/en/libra"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 lg:w-1/3">
                        <div className="card shadow w-80 m-4">
                            <figure>
                                <img src={scorpio} alt="ineq" />
                            </figure>
                            <div className="card-body flex items-center">
                                <img className="avatar"  src={scorpio} alt="ineq" />
                                <h2 className="card-title">Scorpio</h2>
                                <a className="w-full btn bg-purple-600  hover:bg-purple-800 text-white btn-md" href="/horoscope/en/scorpio"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 lg:w-1/3">
                        <div className="card shadow w-80 m-4">
                            <figure>
                                <img src={sagittarius} alt="ineq" />
                            </figure>
                            <div className="card-body flex items-center">
                                <img className="avatar"  src={sagittarius} alt="ineq" />
                                <h2 className="card-title">Sagittarius</h2>
                                <a className="w-full btn bg-blue-700  hover:bg-blue-900 text-white btn-md" href="/horoscope/en/sagittarius"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
    
        <section className="text-gray-600 body-font dark:text-white">
            <div className="container px-5 py-4 mx-auto">
                <div className="flex flex-wrap -m-4">
                    <div className="p-4 lg:w-1/3">
                        <div className="card shadow w-80 m-4">
                            <figure>
                                <img src={capricorn}  alt="slope" />
                            </figure>
                            <div className="card-body flex items-center">
                                <img className="avatar"  src={capricorn}  alt="slope" />
                                <h2 className="card-title">Capricorn</h2>
                                <a className="w-full btn  bg-red-500 hover:bg-red-800 text-white btn-md" href="/horoscope/en/capricorn"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 lg:w-1/3">
                        <div className="card shadow w-80 m-4">
                            <figure>
                                <img src={aquarius} alt="ineq" />
                            </figure>
                            <div className="card-body flex items-center">
                                <img className="avatar"  src={aquarius} alt="ineq" />
                                <h2 className="card-title">Aquarius</h2>
                                <a className="w-full btn bg-purple-600  hover:bg-purple-800 text-white btn-md" href="/horoscope/en/aquarius"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 lg:w-1/3">
                        <div className="card shadow w-80 m-4">
                            <figure>
                                <img src={pisces} alt="ineq" />
                            </figure>
                            <div className="card-body flex items-center">
                                <img className="avatar"  src={pisces} alt="ineq" />
                                <h2 className="card-title">Pisces</h2>
                                <a className="w-full btn bg-blue-700  hover:bg-blue-800 text-white btn-md" href="/horoscope/en/pisces"><ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
    
    </>
      );
}
export default HoroscopeEnMain;